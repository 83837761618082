import "./App.css";
import React from "react";
import Header from "./components/header/Header";
import Home from "./components/home/Home";
import About from "./components/about/About";
import Skills from "./components/skills/Skills";
import Qualification from "./components/qualification/Qualification";
import Work from "./components/portfolio/Work";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import ScrollUp from "./components/scrollup/ScrollUp";
import { BrowserRouter as Router } from "react-router-dom";

const App = () => {
  return (
    <Router>
      <>
        <Header />

        <main className="main">
          <Home />
          <About />
          <Skills />
          <Qualification />
          <Work />
          <Contact />
        </main>

        <Footer />
        <ScrollUp />
      </>
    </Router>
  );
};

export default App;
