// In a new file named DustExplosionVentPanelDialog.jsx
import React from "react";
import Work1 from "../../../assets/work1.jpg";
import BackOfExplosionVent from "../../../assets/backOfExplosionVent.jpg";
import outsideExplosionVent from "../../../assets/outsideExplosionVent.jpg";
import technicalSchematic from "../../../assets/technicalSchematic.jpg";
import currentVentDimensions1 from "../../../assets/currentVentDimensions1.jpg";
import currentVentDimensions2 from "../../../assets/currentVentDimensions2.jpg";
import filterBagVolume from "../../../assets/filterBagVolume.jpg";
import emptyVolCals from "../../../assets/emptyVolCals.jpg";
import dirtyVolCalcs from "../../../assets/dirtyVolCalcs.jpg";
import ventEfficiency from "../../../assets/ventEfficiencyCalcs.jpg";
import effectiveLength from "../../../assets/effectiveLength.jpg";
import effectiveLengthCalcs from "../../../assets/effectiveLengthCalcs.jpg";
import ratioCalcs from "../../../assets/ratioCalcs.jpg";
import ventAreaCalcs from "../../../assets/ventAreaCalcs.jpg";
import excelVentCalcs from "../../../assets/excelVentCalcs.jpg";
import ventDuctCalcs from "../../../assets/ventDuctCalcs.jpg";
import overpressureAgaintVentArea from "../../../assets/overpressureAgaintVentArea.jpg";
import currectSpecVentCalcs from "../../../assets/currectSpecVentCalcs.jpg";
import newSpecVentCalcs from "../../../assets/newSpecVentCalcs.jpg";
import newTechnicalSchematic from "../../../assets/newTechnicalSchematic.jpg";
import threeDVent from "../../../assets/3dnewVent.jpg";

const DustExplosionVentPanelDialog = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "50px" }}>
      <h2>
        1.1 Project: Modification of dust explosion vent panel for a dust
        collector unit{" "}
      </h2>
      <h3>Summary of Results</h3>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 2 }}>
          {/* ... Content ... */}
          The aim of this project was to reduce the chance of a mechanical
          rupture occurring for the K1 dust collector unit, by installing a new
          explosion vent in conjunction with the already existing vent panel. By
          increasing the total relief from 0.36m2 to 0.61m2, the reduced
          overpressure (in the event of an internal vented explosion) has gone
          from 0.8650 barg - which was deemed as not acceptable by the lead
          process engineer, due to large force that would be applied on the
          already damaged 1m2 door – to a value of 0.5292 barg. This has
          resulted in a percentage change of approximately 39% in decrease of
          the overpressure, which would reduce the chance of a mechanical
          rupture occurring during a vented explosion, as there would be less
          stress applied to the system.<br></br>
          <br></br>
          The pressure test that will be conducted, will prove that the DCU can
          contain the overpressure, without any sort of deformation, leak, but
          most importantly a mechanical rupture in the event of an explosion.
          Undertaking a stress analysis for the dust collector unit, presents
          the thickness that is required to ensure that the principle stresses
          does not go above the design stress. This will ensure that during the
          pressure test, the DCU will not be subjected to a stress that would
          lead to failure of the structure. Thickness tests will be carried out,
          using Ultrasonic Thickness (UT) gauges, by an external contractor to
          prove that the actual thickness of the DCU will be greater than the
          theoretical minimum thickness that were calculated in the stress
          analysis. The CV-S 500x500 model and its installation have been quoted
          for a total of £1845.35.
        </div>
      </div>
      <h3>1.1.2 Objectives</h3>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 2 }}>
          <ul>
            <br></br>
            <li>
              • Determine if existing relief area is adequate for safe
              overpressure
            </li>
            <br></br>
            <li>
              •Installation of new explosion vent panel attached to the back of
              the K1 Dust collector unit and provide proposed dimensions.
            </li>
            <br></br>
            <li>
              •Conduct a pressure test to prove K1 DCU can withstand
              overpressure during a vented explosion
            </li>
            <br></br>
            <li>
              •Conduct a stress analysis to determine thickness required to
              ensure DCU can withstand the stresses applied during pressure
              test.
            </li>

            <br></br>
          </ul>

          <p>
            The K1 Dust collector unit was observed to have visible damage to
            the door and signs of corrosions, which could impact the structural
            integrity of the unit, as corrosion can weaken the metal structure
            of the vessel over time. If there was a scenario where there was an
            increase of pressure, it could lead to a catastrophic failure of the
            vessel due to it not being able to withstand the pressure as normal.
            As the dust collector unit has been around for number of years
            (greater than 20 years), historical records or documentation
            regarding its origins or previous usage are not readily available.{" "}
            <br></br>
            <br></br>
            Consequently, there were 3 decisions proposed:
            <br></br> 1. Replace the existing K1 DCU with a brand new one from
            an external supplier.
            <br></br>2. Replace the existing K1 DCU with another existing DCU
            that is not in use, located inside another room.
            <br></br>3. Modification/installation of a new explosion vent panel
            that would be attached to the existing K1 DCU. <br></br> <br></br>
            The decision was made to install a new explosion vent, due to
            short-term plant operation – the plant will only be running for
            approx. 2-5 years, thus was determined that it would not be
            cost-effective to invest in brand new equipment, as the return on
            investment would be low. Moreover, taking into account the time
            necessary for the decommissioning of the old equipment, as well as
            the commissioning and validation for the new DCU, it is a high
            probability that all the time required needed for the process, will
            approach near the end of the site life cycle. <br></br> <br></br>The
            focus was instead on optimising existing equipment to improve its
            safety. A vented explosion in the DCU’s could happen due to the
            characteristics of the dust contained inside. The product powder
            dust has a Minimum Ignition Energy (MIE) of 3 to 4 mJ so it can be
            easily ignited and create an explosion. The pressure generated in an
            internal vented explosion in the DCU’s could create a mechanical
            rupture in the ductwork distribution or DCU that could lead to an
            accident. The project aimed to modify the explosion vent to reduce
            the maximum overpressure caused by an internal vented explosion.
            This could occur if the dust mixture inside the dust collector unit
            is ignited due to all the conditions for a fire/explosion being met,
            and all the other preventative measures for the DCU have failed,
            such as the earthing etc. The British Standard European 14491:2012
            (BS EN 14419) was used to propose a new and appropriate explosion
            vent size. A lot of the standards that are used in sizing explosion
            vents, requires information and parameters such as the dust
            explosive characteristics (Kst) theoretical/effective flame length,
            the effective diameter, volume of the vessel, current vent area,
            vent efficiency, and the maximum pressure (PMax).
          </p>
        </div>

        <div style={{ flex: 1 }}>
          <img
            src={Work1}
            alt="Image 1"
            width="100%"
            style={{ marginTop: 28 }}
          />
          <img src={BackOfExplosionVent} alt="Image 2" width="100%" />
          <img src={outsideExplosionVent} alt="Image 3" width="100%" />
        </div>
      </div>
      {/* ... Rest of the content with a similar structure ... */}
      <h2>1.2 Methodology</h2>
      <h3>1.2.1 Required Data/Parameters</h3>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 2 }}>
          The relevant dimensions for the dust collector unit was obtained by
          using a measuring tape. This allowed the calculation for an
          approximate vessel size, with the shape being a cylindrical base and a
          cone hopper at the bottom. As described in the table above, the volume
          of the vessel can have a large impact on the maximum pressure rise
          during an explosion. Increasing the volume size, decreases the maximum
          rate of pressure rise (generally), as for larger vessels, there is
          more room for the explosion to expand and dissipate its energy, which
          results in a slower rate of pressure rise: (dp/dt)max = 1/V^(1/3).{" "}
          <br></br>
          The diameter of the cylindrical base (larger diameter of the truncated
          cone) and the smaller diameter of the truncated cone was measured by
          determining the circumference of the circles. It should be noted that
          the external circumferences were measured, due to assumption of this
          DCU being approximated to a thin- walled vessel, thus the thickness of
          the construction material was assumed to be negligible when
          calculating the volume . Then by using the formula, C= pi*d the
          diameter is obtained by rearrangement. (Image to the right) The volume
          of the cylindrical base is calculated as followed: <br></br>Volume of
          cylinder = 𝜋 ∗ 𝑟2 ∗ h . And the volume of the truncated cone at the
          bottom can be determined by:<br></br> Volume of Truncated cone =
          1/3*pi*(d^2/4 + d/2 *d0/2 + d0^(2)/4)*heightOfCone.
        </div>
        <div style={{ flex: 1 }}>
          <img src={technicalSchematic} alt="Image 4" width="100%" />
        </div>
      </div>
      <h3>1.2.2 Current Vent Dimensions</h3>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 2 }}>
          {" "}
          <img src={currentVentDimensions2} alt="Image 6" width="100%" />
          {/* ... Content ... */}
          The current explosion vent was measured to be 600mm by 600mm, with a
          vent duct of approximately 1100mm long (shown in images above). The
          uncertainty arises from instrumentation limitations and human errors,
          so the latter was reduced as much as possible, by repeated measurement
          with different personnel. The implementation of a duct is an essential
          design feature because it affects the real decreased explosion
          pressure achieved in the protected vessel, and thus it was important
          that this component was measured.
        </div>
      </div>
      <h3>1.2.3. BS EN 14491</h3>
      <h4>1.2.3.1 Assumptions</h4>
      {/* ... Content ... */}
      The calculations are only valid when based upon the assumptions stated
      below :<br></br> • “Volume in between 0.1 and 10000 m3”<br></br>• “Static
      activation pressure of the vent in between 0.1 and 1 bar g”<br></br>•
      “Reduced explosion pressure in between 0.1 and 2 bar g, and should be at
      least equal to the static activation pressure 2 times the vent burst
      pressure tolerance”<br></br>• “Pmax in between 5 and 10 bar if Kst is in
      between 10 and 300 bar.m.s-1”<br></br>• “Length/Diameter ratio L/D in
      between 1 and 20 ; with vertical shaped hoppers (if the shape is
      horizontal, this method will not apply)”<br></br>• “Pressure at ignition
      less than 110 kPa and oxygen concentration less 21%”<br></br>• “Efficiency
      of explosion vent is assumed to be 80%”<br></br>• “Top cover of dust
      collector unit is assumed flat, instead of a semi-ellipsoid head to
      simplify calculations.”<br></br>
      <h4>1.2.3.2 Filter Bags Considerations</h4>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 2 }}>
          {/* ... Content ... */}As the filter bags take a certain amount of
          volume inside the DCU, it can be taken from the volume that is
          required to reduce the overpressure i.e., the protected volume.
          Depending on certain conditions, the space between the filter bags
          themselves (the “clean” space) can also be removed. If the space
          between the bag is greater than the radius/width, then only the filter
          bag volume can be removed.<br></br> This is due to the fact that the
          clean space volume is relatively small compared to the volume of the
          filter bags thus it may be sufficient to remove only the filter bag
          volume to prevent the accumulation of combustible dust and minimize
          the risk of a dust explosion. But if the radius/width is larger, then
          bag volume and the clean space volume can be removed as the clean
          space volume is relatively large compared to the filter bag volume,
          removing only the filter bag volume might not be enough to effectively
          prevent dust accumulation and mitigate the risk of a dust explosion.
          <br></br>
          <br></br>
          (Shown on the image below) Despite engaging in discussions with the
          engineer that carries out the maintenance and replacement of the
          filter bags inside the DCU, a definitive conclusion could not be made
          regarding if the relative dimensions of the spacing between the bags,
          exceed the width (inside the DCU there are rectangular-shaped filter
          bags instead of cylindrical-shaped). Thus, it was determined to
          disregard the volume of the interstitial space between the filter bag
          from the calculations, to ensure that the volume that is required to
          be protected isn’t reduced, otherwise it could lead to a vent area
          size that would be insufficient, in other words, the protective
          measures are based upon a conservative estimate. <br></br>As it’s
          required to calculate the volume of the filter bags, the
          specifications were obtained from the manufacturer. 20 filter bags are
          present inside the dust collector, and with all the specifications now
          obtained, the filter bag volume can be calculated: Volume of Bag =
          L*W*H*No.ofBags = 1030*2.1*517*20 = 22365420𝑚𝑚3 = 0.02236. . 𝑚3
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <img src={filterBagVolume} alt="Image 7" width="100%" />
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 2 }}>
          {/* ... Content ... */}The empty volume of the dust collector unit is
          the cylindrical base and the hopper attached to it on the bottom:
          <img src={emptyVolCals} alt="Image 8" width="100%" />
          Therefore, the dirty volume (i.e., the volume that is required to be
          protected) is:
          <img src={dirtyVolCalcs} alt="Image 9" width="100%" />
        </div>
      </div>
      {/* ... and so on ... */}
      <h4>1.2.3.3 Venting Efficiency</h4>
      <div style={{ flex: 2 }}>
        {/* ... Content ... */}As the experimental data used to produce these
        formulas were not based upon proprietary explosion vents, the mass of
        the vents was not taken into account, which impacts the performance of
        the vents. EN 14797 gives certain guideline on assigning efficiency
        values, and can be roughly determined by the value of the specific mass.
        The mass of the current vent was obtained from the manufacturer, which
        was 2.2kg, with an area of 0.36m2. Scaling it up to 1m2, the following
        is obtained: Mvent = 2.2kg/0.36m^2 Mvent = 6.1kg/m^2 The condition for
        the vent to be 100% efficiency is not met:
        <img src={ventEfficiency} alt="Image 10" width="100%" />
        The theoretical maximum vent height (keeping the width constant) would
        be 1800mm, which would give a required vent area of 1.08m2, and even
        with this value inserted into the constraint would still be insufficient
        to get a value below 0.07. Therefore, we can’t assume the vent
        efficiency to be 100%.<br></br> With discussion with the lead process
        engineer, it was confirmed that a value of 80% efficiency was to be
        used, to assume the worst-case scenario.
      </div>
      <h4>1.2.3.4 Standard Applicability</h4>
      <div style={{ flex: 2 }}>
        {/* ... Content ... */}For this standard to be applicable, the L/D ratio
        has to be within a specific range which is stated above. The ‘L’ term is
        the effective length which is the maximum distance will need to travel
        in order to escape the vessel/DCU through the explosion vent, which is
        from the bottom of the cylindrical base to the top of the explosion
        vent. The K1 DCU has a hopper that is attached to the bottom of the DCU,
        and consequently effective length will also include 1/3 of the hopper
        height.This parameter was measured manually with a measuring tool:
        <img src={effectiveLength} alt="Image 11" width="100%" />
        The effective length was approximated to be:
        <img src={effectiveLengthCalcs} alt="Image 12" width="100%" />
        The ‘d’ term is the effective diameter. To calculate the effective
        diameter, the effective volume has to be first determined, which is the
        cylinder volume from the bottom of the base to the top of explosion vent
        panel, and 1/3 of the cone volume. It should be noted, that as it’s a
        truncated cone, there are few more variables required, such as the
        larger and smaller diameter:
        <img src={ratioCalcs} alt="Image 13" width="100%" />
      </div>
      <h4>1.2.3.5 Calculations</h4>
      <div style={{ flex: 2 }}>
        {/* ... Content ... */}The geometric area is calculated as followed:
        <img src={ventAreaCalcs} alt="Image 14" width="100%" />
        By keeping the width constants and increasing height size in increments
        of 50mm, a range of the required vent areas was produced, and taking the
        vent efficiency into account, the geometric area is calculated. Using
        Excel Solver (Image Below), parameter A was set to equal to the
        geometric area, by only changing the reduced overpressure, as the other
        variables were either obtained from the material safety data sheet or
        assumed, such as the static activation pressure (0.11barg).
        <img src={excelVentCalcs} alt="Image 15" width="100%" />
        By doing this for a range of areas, the reduced over pressure is
        obtained for each relief vent area.
      </div>
      <h4>1.2.3.6 Influence of explosion vent duct</h4>
      <div style={{ flex: 2 }}>
        {/* ... Content ... */}The K1 Dust collector unit has a duct that is
        attached to the back, which is approximately 1100mm in length. This
        length of a duct has to be taken into consideration, as it influences
        the reduced overpressure. This is due to the fact that it restricts the
        flow of hot gases and any unburnt fuel that is transferred inside the
        duct can potentially be ignited which can consequently cause secondary
        explosions and propagate back into the DCU, slowing down the venting
        process.<br></br>
        <br></br>
        The reduced overpressure with a duct is calculated from the following
        equation:
        <img src={ventDuctCalcs} alt="Image 16" width="100%" />
      </div>
      <h2>1.3 Results</h2>
      <h3>1.3.1 Reduced Overpressure against range of Vent Areas</h3>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 2 }}>
          {/* ... Content ... */}
          <img src={overpressureAgaintVentArea} alt="Image 17" width="100%" />
          The graph above illustrates the relationship between vent area and the
          reduced overpressure, as analysed according to the methodology
          outlined in BS EN 14491. The findings demonstrate a consistent trend
          wherein an increase in vent area correlates with a significant
          decrease in reduced overpressure, which emphasizes the critical
          importance of accurately sizing an explosion vent panel to ensure the
          right amount of pressure relief.
        </div>
      </div>
      <h3>1.3.2 Feasible Explosion Vent Model</h3>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 2 }}>
          {/* ... Content ... */}
          The next step was to find the most appropriate size that could be used
          and installed below the current explosion vent. From the bottom of the
          current explosion vent to the base of the cylindrical section, there
          was approximately 1100mm of free space. Though, it was mentioned by
          the lead process engineer, that there should be a considerable amount
          of space left between the bottom of the current explosion vent panel
          to the top of the new installed vent panel, and space between the
          bottom of the installed explosion panel to the base of the cylindrical
          section, to ensure that the structural integrity was not compromised.
          The domed VSP-L model was first proposed by the suppliers, though the
          burst activation pressure proposed is a low value and would be
          suitable for the system, the vacuum rating does not meet the
          requirement for the system (as the DCU runs under vacuum conditions),
          and too weak which would cause the vent panel to constantly collapse
          on itself every time the vacuum pump was started. In addition to this,
          the vent size would be too large to ensure space was left (as
          mentioned above), which could compromise the structural integrity.{" "}
          <br></br>
          <br></br>Another supplier was contacted, and with the conditions about
          the systems discussed, a suitable explosion vent model was proposed –
          Rectangular shaped CV-S. This specific model is designed for use in
          applications where the operating pressure approaches the burst
          pressure range, or where high vacuum pressure may exist.
          <br></br>
          <br></br>It was determined that the 500mmx500mm would be the most
          appropriate size, as it would allow adequate spacing between the
          current explosion vent panel, and the cylindrical base.
        </div>
      </div>
      <h3>
        1.3.3 Calculated Reduced Overpressure for Current and Proposed Relief
        Area
      </h3>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 2 }}>
          {/* ... Content ... */}
          It was first necessary to calculate the overpressure for the current
          specifications of the explosion vent, in order to determine the
          percentage decrease.
          <img src={currectSpecVentCalcs} alt="Image 17" width="100%" />
          The value of 0.8650 barg was deemed “not acceptable” by the lead
          process engineer and as there was no information on the maximum
          allowable working pressure (MAWP), the decision to assess the
          suitability, was based upon professional judgment and engineering
          expertise, though the main reason being, the considerable force being
          applied and the equipment’s aging condition, with visible damage and
          corrosion on the 1m2 door. <br></br> <br></br> With all the relevant
          variables and parameters now known for the new explosion vent, the
          reduced overpressure can be calculated for the increased total relief
          area:
          <img
            src={newSpecVentCalcs}
            alt="Image 18"
            width="100%"
          /> <br></br> <br></br>
          The implementation of the new explosion vent panel has yielded a
          substantial increase in the total relief area, expanding it by 0.25m2.
          Therefore, this modification has notably impacted the reduced
          overpressure, reducing it from an initial value of 0.862 barg to a
          revised level of 0.5292 barg. This installation corresponds to a
          decrease of approximately 39% in the reduced overpressure, reducing
          the stress applied to the vessel, which would ultimately decrease the
          likelihood of a mechanical rupture occurring. Technical schematic and
          3-D models were produced by myself to visually portray the
          installation of the new vents.They were incorporated into the User
          Requirement Specification (URS), serving as a visual aid for the
          suppliers and relevant personnel, and to also communicate the specific
          dimensions and configuration of the new explosion vent, facilitating a
          clear understanding among stakeholders involved in the project
          <img src={newTechnicalSchematic} alt="Image 19" width="100%" />
          <img src={threeDVent} alt="Image 20" width="100%" />
        </div>
      </div>
      <h3>1.3.4 Pressure Relief and Stress Analysis</h3>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 2 }}>
          {/* ... Content ... */}As part of the project, it was tasked to
          formulate a comprehensive procedure/strategy for conducting pressure
          testing on the dust collector unit subsequent to the installation of
          the new explosion vent, using the GES 303- Pressure Testing as
          guidance. A systematic approach was developed, comprising a series of
          key steps. The system will undergo a hydrostatic pressure test, in
          which the purpose of this test is to verify the seal integrity and
          safety of the K1 dust collector unit after the modification of the
          explosion vent that is attached to the back of the unit. The new total
          relief area aims to reduce the overpressure to 0.5292 barg as per
          calculations, in the event of an internal explosion. With accordance
          with the GES 303, the strength test and leak test will be completed at
          approximately 2 times the overpressure i.e., approximately 1.1 barg.
          It will need to be ensured that an adequate supply of test medium is
          available for the test, and the dust collector unit is free of debris
          and dirt. Due to the large weight of the test medium, there may be a
          requirement for supports to be implemented to provide added stability
          during the pressure test, and the locations and number of supports
          must be determined appropriately. Any air pockets must be vented from
          the system, when supplying the water source to the DCU. The required
          and appropriate safety devices, with suitable calibration
          certificates, must all also be installed during the test. The
          restricted area will only be accessed to mandatory personnel, and
          appropriate signage and barriers are placed. The test pressure must be
          raised incrementally, with phase 1 being no greater than 50% of the
          maximum strength test pressure. The pressure will then increase by 10%
          increments to the maximum test pressure, and inspections must be
          carried out at each increment before raising further (testing must not
          be left unattended). Once the test pressure is reached, the test must
          be held for a minimum of 10 minutes and witnessed by a GSK
          representative. The safe release of pressure must also be carried out
          incrementally, and the test water must be disposed of in accordance
          with site requirements using a process drains. In the event of a leak
          at any stage in the test, pressure is to be fully relieved from the
          system before appropriate repairs are made and the test is repeated.
          <br></br>
          <br></br>
          Slip plates were put in place in the positions identified by the lead
          process engineer and myself. Slip plates thicknesses were calculated
          using GSK documents, and the minimum thicknesses were found for each
          slip plate positions. <br></br>
          <br></br>Without the required data for the unit, such as the maximum
          allowable working pressure (MAWP), it is not certain that the dust
          collector unit would be able to withstand the load and pressure during
          the pressure test. Therefore, it is the utmost importance that a
          stress analysis is undertaken on the system to ensure that the
          stresses applied (during the pressure test) does not go above the
          allowable stress and will evaluate its structural integrity to ensure
          safe operation. The stress analysis serves as a crucial step in
          understanding the response of the dust collector unit under the
          loading conditions, as well as identifying any potential areas of
          concern that may require further examination/modification.<br></br>
          <br></br>
          Due to complex and comprehensive calculations for the stress analysis,
          they have not been included in here, but please do let me know if
          you're interested on how I went going about doing this! Though pretty
          much, I used the approximation of a thin walled vessels, which helped
          to simply the calculations by quite a bit. But essentially, it could
          be reasonably assumed that the DCU maintains a uniform thickness and
          greater than 2.3mm throughout the system, otherwise there would be
          visible flex. Thicknesses tests would be carried out to confirm these
          values.
        </div>{" "}
      </div>
    </div>
  );
};

export default DustExplosionVentPanelDialog;
