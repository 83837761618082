import React from "react";
import PrecliniqueVideo from "../../../assets/PrecliniqueWebsiteShowCase.mp4";

const PrecliniqueDialog = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "50px" }}>
      <h2>Project: Preclinique </h2>

      <h3>Project Objective</h3>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 2 }}>
          {/* ... Content ... */}For this web application, myself and a couple
          of med students from Imperial are designing and producing a platform
          for pre-clinical medical students to practice questions that mimic
          their universities style of questioning. The med students are crafting
          the BEST and the most TAILORED practice questions available for
          students' medical school, (while I craft a user-friendly web
          application, no pressure right?!) and is unlike anything on the market
          and we hope to truly revolutionise how pre-clinical medical students
          revise & prepare for their summative exams behold...{" "}
          <strong>Preclinique</strong>, medical exams made easy! <br></br>
          <br></br>The technology stack that I am using for this web app is the
          PERN stack, which stands for PostgreSQL (a relational database),
          Express (framework for Node.js), ReactJS(frontend framework for JS)
          and NodeJS(backend language using JS). We'll hopefully be using
          AmazonWebService EC2 to host the web app, and use AWS S3 to store
          data/files. Stay tuned for the progress for this web application, but
          for now, check out the video for the application below! (The work is
          in progress, and soon there will be a beta release near the end of
          this year/Q1 of next year! )
          <div style={{ display: "flex", justifyContent: "center" }}>
            <video style={{ width: "100%", maxWidth: "800px" }} controls>
              <source src={PrecliniqueVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrecliniqueDialog;
