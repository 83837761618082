import React, { useState, useEffect } from "react";

// import data
import { projectsData } from "./Data";
import { projectsNav } from "./Data";

// import components
import WorkItems from "./WorkItems";
import ProjectDetailsDialog from "./ProjectDetailsDialog";

const Projects = () => {
  const [item, setItem] = useState({ name: "all" });
  const [projects, setProjects] = useState([]);
  const [active, setActive] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    if (item.name === "all") {
      setProjects(projectsData);
    } else {
      const newProjects = projectsData.filter((project) => {
        return project.category.toLowerCase() === item.name;
      });
      setProjects(newProjects);
    }
  }, [item]);

  const handleProjectClick = (project) => {
    setSelectedProject(project);
    setOpen(true);
  };

  const handleClick = (e, index) => {
    setItem({ name: e.target.textContent.toLowerCase() });
    setActive(index);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div class="work__filters">
        {projectsNav.map((item, index) => {
          return (
            <span
              onClick={(e) => {
                handleClick(e, index);
              }}
              className={`${active === index ? "active-work" : ""} work__item`}
              key={index}
            >
              {item.name}
            </span>
          );
        })}
      </div>

      <div className="work__container container grid">
        {projects.map((item) => {
          return (
            <WorkItems
              item={item}
              key={item.id}
              onClick={() => handleProjectClick(item)}
            />
          );
        })}
      </div>

      <ProjectDetailsDialog
        open={open}
        project={selectedProject}
        onClose={handleClose}
      />
    </div>
  );
};

export default Projects;
