import React from "react";
import "./work.css";
import Works from "./Works";

const Work = () => {
  return (
    <section class="work section" id="projects">
      <h2 class="section__title">Projects</h2>
      <span class="section__subtitle" id="section__subtitle__projects">
        My recent projects include... <br></br>(Please note, one of the
        engineering projects detailed below, in collaboration with GSK
        (GlaxoSmithKline), is classified as proprietary information, thus there
        is no additional information. For the others, all permissions from the
        relevant people have been obtained to share the details and outcomes of
        these project)
      </span>

      <Works />
    </section>
  );
};

export default Work;
