import Work1 from "../../assets/work1.jpg";
import Work2 from "../../assets/work2.jpg";
import Work3 from "../../assets/work3.jpg";
import Work4 from "../../assets/work4.jpg";
import DustExplosionVentPanelDialog from "./EachProjectDialogContent/DustExplosionVentPanelDialog";
import WaxetilBellowsUpgradeDialog from "./EachProjectDialogContent/WaxetilBellowsUpgradeDialog";
import PrecliniqueDialog from "./EachProjectDialogContent/PrecliniqueDialog";

export const projectsData = [
  {
    id: 1,
    image: Work1,
    title:
      "Modification/Installation of a New Dust Explosion Vent Panel for the K1 Dust Collector Unit",
    category: "Engineering Projects",
    dialogComponent: DustExplosionVentPanelDialog,
  },
  {
    id: 2,
    image: Work2,
    title: "Waxetil Bellows Upgrade",
    category: "Engineering Projects",
    dialogComponent: WaxetilBellowsUpgradeDialog,
  },
  {
    id: 3,
    image: Work3,
    title: "Leak Testing Development",
    category: "Engineering Projects",
    confidential: true,
  },
  {
    id: 4,
    image: Work4,
    title: "Preclinique",
    category: "Web Application",
    dialogComponent: PrecliniqueDialog,
  },
];

// projects
export const projectsNav = [
  {
    name: "all",
  },
  {
    name: "Engineering Projects",
  },
  {
    name: "Web Application",
  },
];
