import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

const ProjectDetailsDialog = ({ open, project, onClose }) => {
  const DialogContentComponent = project?.dialogComponent;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{project?.title}</DialogTitle>
      <DialogContent>
        {DialogContentComponent && <DialogContentComponent />}
      </DialogContent>
    </Dialog>
  );
};

export default ProjectDetailsDialog;
