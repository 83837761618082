import React from "react";
import CIPcasings from "../../../assets/CIPcasings.jpg";
import acetoneDensity from "../../../assets/acetoneDensity.jpg";
import hydrostaticPressure from "../../../assets/hydrostaticPressure.jpg";

import advantagesOfBellows from "../../../assets/advantagesOfBellows.jpg";
import advantagesOfBellows2 from "../../../assets/advantagesOfBellows2.jpg";
import possibleSetupfor1stBellow from "../../../assets/possibleSetupfor1stBellow.jpg";
import possibleSetupfor2nd3rdBellow from "../../../assets/possibleSetupfor2nd3rdBellow.jpg";

const WaxetilBellowsUpgradeDialog = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "50px" }}>
      <h2>1.1 Project: Waxetil Bellows Upgrade </h2>
      <p>
        <strong>
          Note: I could not share GSK PIDs (Process Instrumentation Diagrams)
          due to confidentiality, so if the analysis gets confusing, please
          forgive me, as I would have love to show and highlight to what
          positions I am referring to{" "}
        </strong>
      </p>
      <h3>Summary of Results</h3>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 2 }}>
          {/* ... Content ... */}
          For this project, research and analysis was conducted to optimise the
          design of bellows, that are used during the CIP processes for powder
          transfer systems. The main objectives being:<br></br> <br></br>1. The
          feasibility of the removal of the bellows, which would ultimately
          remove a leak point all together as mentioned above<br></br>
          <br></br> 2. Replacing the existing bellows and jubilee clamps with
          anti-static pressure rated bellows that are more suitable for the
          conditions<br></br>
          <br></br> 3. Acquire pressure rated CIP casings.<br></br>
          <br></br>
          This work was carried out to reduce a hazardous scenario from
          occurring, specifically the accumulation of <strong>
            ACETONE
          </strong>{" "}
          (CIP Fluid) that could lead to a pool fire if ignition source was
          found, and to overall improve process safety of the process. Through
          collaboration with the process engineer and thorough examination of
          system operating conditions, it was determined that the system
          exhibited minimal thermal expansion, negligible mechanical movements,
          though only the 1st set of bellows was confirmed to have satisfactory
          pipe alignment. Consequently, it was theoretically possible to
          eliminate the 1st set of bellows altogether, considering the system's
          specific characteristics. Comparative analysis indicated that removing
          the existing bellows would represent the most cost-effective option,
          as opposed to acquiring stainless steel corrugated bellows or rubber
          synthetic bellows. This finding offered potential cost savings without
          compromising system integrity or performance.
        </div>
      </div>
      <h3>1.1.2 Objectives</h3>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 2 }}>
          <br></br>
          <li> Waxetil K-Tron Bellows Project upgrade feasibility</li>
          <br></br>
          <li>
            Redesigning tee piece to allow elimination of one set of bellows
          </li>
          <br></br>
          <li>
            Installing permanent anti-static pressure related bellows to one set
            of bellows (on powder discharge chute) similar to Russel sieve
          </li>
          <br></br>
          <li>
            Propose replacement pressure rated bellows for CIP use only for one
            set of bellows
          </li>
          <br></br>
          <li>
            Reduce likelihood for human error by: a. Eliminating number of
            bellows b. Minimising likelihood for human error by providing more
            ergonomic design
          </li>
          <br></br>
          <h3>1.1.3 Justification</h3>
          <li>
            The current installed CIP casings is not confirmed to prevent a Loss
            of Containment
          </li>
          <br></br>
          <li>
            Current bellow arrangements identified as high risk for Loss of
            Containment during a process hazard process
          </li>
          <br></br>
          <li>
            Current bellows arrangement has increased reliance on operator
            interaction which present a higher likelihood of human error and,
            possible escalation to Major Accident Hazard scenario.
          </li>
          <br></br>
          <h3>1.1.4 Function of CIP casings</h3>
          <p>
            CIP casings (image below) are placed and fitted around each set of
            bellows (image below) during the CIP process. They have numerous
            functions, though main 2 being: <br></br> <br></br> 1. Reinforcement
            and protection of bellows during the CIP process. They provide an
            additional layer of support, ensuring that the bellows can withstand
            any pressures (e.g., the hydrostatic pressure of the fluid) in the
            system, temperature variations, and chemical exposures associated
            with the cleaning agent. <br></br> <br></br> 2. Reduces the
            likelihood of fluid escaping and a potential hazardous scenario
            occurring. <br></br> <br></br>Overall, by enclosing the bellows
            within the casings, it plays a critical role to ensure the bellows
            integrity & longevity are preserved.
          </p>
          <img src={CIPcasings} alt="Image 1" width="100%" />
          <br></br>
          <h3>1.1.5 Impact of Thermal Expansion</h3>
          Thermal expansion can simply be defined as the tendency for the
          component to change its volume as the temperature varies. One simple
          example that can explain this phenomenon is the expansion of
          compressible fluids. It was important to check whether thermal
          expansion will be a critical factor, as a compound that has a high
          tendency to expand at lower temperatures could lead to adverse effects
          on the piping such as increased thermal stress.
          <h3>1.1.6 Operating temperature and pressure</h3>
          <br></br>
          The operating temperature for the CIP is approximately @ 40 °C, and
          thus in this range, acetone density is relatively constant at all
          different operating pressures.
          <img src={acetoneDensity} alt="Image 1" width="100%" />
          <br></br>
          <br></br> <br></br>At this range the approximation for acetone to be
          incompressible would be feasible, and so the effect of thermal
          expansion will be quite low in the project’s case. As stated above,
          the operating temperature for the CIP process is approx. at 40°C, and
          the only pressure that needs to be taken into account is the
          hydrostatic pressure of the fluid, i.e., the pressure exerted by the
          fluid due to a force, and this case it will be gravity. With the
          maximum height of acetone being 3m (height of the pipe and assuming
          acetone is fully filled), the maximum hydrostatic pressure can be
          determined with the following equation :<br></br> <br></br>
          <img src={hydrostaticPressure} alt="Image 1" width="auto" />
          <br></br>
          <h3>1.1.7 Defining the problem</h3>
          The Waxetil facility commenced operations 18 years ago, with minor
          modifications implemented subsequently. The present configuration of
          the CIP system employed in the vicinity of the K- Trons is deemed to
          be out-dated and not considered good engineering practise for carrying
          a flammable liquid. With the current design around the bellows not
          being pressure-rated, despite being subjected to a maximum of 0.5 barg
          static head pressure during CIP operations. Without a certified
          pressure rating, these casings may not be able to withstand the
          pressure, which can lead to failure and potential leakage of the
          flammable liquid being processed. The current production (the transfer
          of powdered Stearic Acid and Cefuroxime Axetil) and CIP set- up -with
          the cleaning agent being acetone- (image cannot be shown due to
          confidentiality of GSK's PIDs) around the K-Tron has a high amount of
          operator dependence; requiring operators to a lot of manual activities
          such as:
          <br></br>
          <br></br>
          1. Manually inspecting bellows, <br></br>
          <br></br>2. replacing bellows,<br></br>
          <br></br> 3. tightening jubilee clamps<br></br>
          <br></br> 4. and installing the protective casings around the bellows.
          <br></br>
          <br></br>
          An error made within any of these individual tasks could have
          subsequent consequences which could escalate into a major accident
          hazard (MAH) scenario. The Ulverston – Major Accident Hazard scenario
          identifies a credible pool fire scenario escalating from the loss of
          primary containment during the K-Tron. The risk index value was given
          to be 12, a severity score of 3 and likelihood value of 4. Following
          the PHR process a critical task analysis study was conducted on the
          Waxetil CIP process. The study highlighted a high criticality task of
          fitting of bellows supports prior to flood- filling. There were
          numerous human errors modes such as failure to install, and failure to
          tighten bolts (fitting of the CIP casings) that would lead to a
          significant loss of containment, and thus a fatality scenario. This
          project and installation would entirely decrease the human reliance
          and strengthen the control measures from procedural to engineering.{" "}
          <br></br>
          <br></br>
          <h3>1.1.8 Gathering information</h3> <br></br>
          There are 5 sets of bellows in question. The 1st set being above the
          volumetric feed, 2nd & 3rd set being below the volumetric feeder but
          above the gravimetric feeder. The 2nd set of bellows is positioned in
          a manner that allow for a degree of mobility and separation from the
          volumetric feeder, due to specific configuration of the piping system.
          Lastly, the 4th set, and 5th set of bellows – below the gravimetric
          feeders (H202 & H204) -have the same configuration as the 2nd and 3rd
          set, so any changes made on them could also be carried out for the
          ones below.
          <h3>1.1.9 Existing bellow and CIP casings design</h3>
          <br></br>A thorough investigation of the existing bellow design was
          conducted by means of a site visit to the plant and area.
          Consultations with the technicians was also beneficial as they
          provided their valuable input to the design, and so provided a
          comprehensive understanding of the design’s strengths and weaknesses.
          Each position consists of two silicone sheaths, one over the other and
          manually assembled onto straight ended pipe and are held in place with
          jubilee clip at each end. <br></br>
          <br></br> To reinforce the bellows for clean-in-place (CIP)
          operations, they are enclosed within a manually assembled and
          removable casing to ensure that it can contain any acetone. The design
          exhibits good flexibility, which is able to withstand lateral
          misalignment of pipe ends, and also achieves the required accuracy on
          gravimetric feed system below, though was mentioned by the process
          engineer that only the set of bellows in position 3 impacts the
          system. The gravimetric feed system is a mechanism used to accurately
          measure and control the flow rate of materials, typically powders or
          solids, by weight and relies on precise measurements to ensure the
          desired quantity of material is delivered. The existing bellow design
          has been tested and evaluated - i.e., the validation process, a
          typical process involving a series of steps, including design
          verification, testing, and producing documentation to confirm that the
          design meets the specific requirements and performs as intended - in
          combination with the gravimetric feed system, and consequently any
          changes specifically to the 3rd set of bellows would most likely
          require validation. Lastly, the design is suitable for powder
          transfer, but as stated is the sections above, it is clear that it is
          not considered good practice for flammable liquids. For the installed
          CIP casings, they have no stated pressure rating and so it is unsure
          whether they can withstand the static head of acetone. The integrity
          of the bellows, when CIP casings are installed, is influenced by
          various factors such as pipe alignment, the position of the sheath,
          the positioning and tension of the Jubilee clips, the condition of the
          casing, and any potential damage or abrasion to the sheath caused
          during installation or by the casing's fastening bolts. <br></br>The
          pipes that are attached to the first set of bellows have a nominal
          diameter of approximately 200mm, while the piping that are attached to
          the rest of the bellows have an estimated nominal diameter of 100mm.
        </div>
      </div>
      <h3>1.1.10 Different type of bellows</h3>
      There are many types of bellows, each for its specific application and
      conditions. If bellows could not be removed entirely, then there would
      need to be a suitable pressure rated bellows to replace the existing
      silicone bellows. The aim was to find the most ideal type. There are
      normally three types of bellows that are used in the pharmaceutical
      industry which the advantages and disadvantages are detailed in the table
      below:
      <img src={advantagesOfBellows} alt="Image 1" width="auto" />
      <img src={advantagesOfBellows2} alt="Image 1" width="auto" />
      Considering the low operating pressures and temperatures of the system,
      the implementation of stainless-steel corrugated bellows would be quite
      excessive, though still would be feasible. One supplier stated that this
      type of bellows be a much better solution for its application and can
      manufacture 316 stainless steel bellows for aseptic use, which uses an
      open convolution design for drain clean from CIP. These specific expansion
      joint designs are commonly used for pharmaceutical powder and other
      hygienic & aseptic applications. It was discussed between myself and the
      process engineer that rubber-synthetic bellows would likely be a more
      appropriate substitute, specifically EPDM (ethylene propylene diene
      monomer rubber) bellows, which are also suitable with acetone and
      conductive (surface resistance less than 1x10^4 Ω). Replacing of the
      jubilee clamps would also be required. Main two replacement either being
      flange connections, such as PN10-16/ASA150, or tri-clamps ferrules e.g.,
      ASME BPE. Both would be suitable to ensure a leak-tight connection.
      <h3>1.1.11 Proposed Modifications </h3>
      The proposed design given by myself, in which the installation would
      change the current arrangement for the 3 sets of bellows were:
      <li>
        The bellows on the batching chute (on top of H201 & H203) will either be
        upgraded to pressure rated anti-static bellows , similar to current
        installation on Russel sieve inlet– for use during manufacturing and
        CIP. Another option was to remove them entirely, and replace with 316L
        stainless steel piping, with the required specification .
      </li>
      <li>
        The bellows in between the hoppers (used for CIP overflow) will be
        removed entirely and a CIP overflow connection made.
        <li>
          o This would require re-design and fabrication of a new screw feeder
          tee piece that currently holds the bellows, and the metal brackets to
          be fully removed.
        </li>
      </li>
      <li>
        The final set of bellows (position 3) will remain as is during
        manufacturing – due to the high sensitivity on the load cells inside the
        gravimetric feed systems (H202 & H204)
        <li>
          o Pressure rated bellows with a suitable tri-clover connection/ flange
          will be designed and need to be installed prior to CIP.
        </li>
      </li>
      <img src={possibleSetupfor1stBellow} alt="Image 1" width="auto" />
      <img src={possibleSetupfor2nd3rdBellow} alt="Image 1" width="auto" />
      It should be noted, anything that is done for the 2nd and 3rd set of
      bellows can be done for the 4th and 5th set of bellows, as they have the
      same pipe configuration. Though, the feasibility of removing the bellows
      hinges upon the crucial factors stated below: <br></br> <br></br>
      1. The absence of thermal expansion in the fluid <br></br>
      2. Negligible mechanical movement of the pipes <br></br>
      3. Proper alignment of the pipes <br></br>
      <br></br>
      As derived earlier, the effect of thermal expansion of acetone is quite
      low due to the low operating temperature and pressure. The process
      engineer determined that the mechanical movement of the pipes was quite
      negligible, thus the only information needed is to determine which set of
      pipes are aligned, and thus the bellows for that location can be removed.
      With regards to cost analysis, the removal of the bellows would be the
      most economical solution when compared with obtaining different type of
      bellows, such as stainless-steel bellows or rubber synthetic bellows and
      in addition to being low-cost, it would remove the leak point
      all-together, which reduces the chance of a hazardous scenario from
      occurring. The removal of bellows might not be suitable for all sets of
      bellows, but the substitute bellows will still be more suitable for the
      conditions, as not only will they be anti-static & pressure rated, but it
      will reduce the overall operator dependency. With previous high-level
      design discussion, the main concern centred around the proposed
      modification to the bellows on the batching chute (position 1) and the 2nd
      set of bellows between the volumetric and gravimetric hoppers (position 2
      ). <br></br> <br></br> It was felt that if the project was implemented as
      above, the stiffness of the bellows and CIP connection would not
      sufficiently isolate hoppers (H201 and H203) from the rest of the system
      to allow required resolution of these loadcells WE-201 and WE-203.
      Although the resolution of these loadcells is not as critical as those for
      H202 and H204, WE-201 and WE-203 were used to call for additional product
      into their respective hoppers and the risk to production of implementing
      the above modifications without full knowledge of implications of changing
      these bellows until project commissioning/validation was considered too
      great. Process safety advised that the current risk score of the system as
      is, and CIP set up was sufficiently high that not undertaking the project
      was not a viable option. It was therefore agreed that the all-production
      bellows (position 1,2, & 3, with whatever was done on 2 & 3, would also be
      carried out on 4 & 5 due to them having the same configuration) should be
      modified such that they can be easily replaced with pressure-rated bellows
      and better fittings/connections during CIP only. It was envisaged that the
      production bellows would look similar to the CIP bellows but be
      manufactured from much more flexible material, and it to be pressure rated
      above the maximum system pressure during the CIP process.
      {/* ... Rest of the content with a similar structure ... */}
    </div>
  );
};

export default WaxetilBellowsUpgradeDialog;
