import React from "react";
import Dialog from "@mui/material/Dialog";

const WorkItems = ({ item, onClick }) => {
  return (
    <div className="work__card" key={item.id}>
      <img src={item.image} alt="" className="work__img" />
      <h3 className="work__title">{item.title}</h3>
      {!item.confidential && (
        <button className="work__button" onClick={onClick}>
          Click here for more info about the project ...
          <i className="bx bx-right-arrow-alt work__button-icon"></i>
        </button>
      )}
    </div>
  );
};

export default WorkItems;
